export const logo = ['608 134', `
  <title>coreui react pro</title>
  <g transform="translate(0.000000,218.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 1090 l0 -1090 3160 0 3160 0 0 1090 0 1090 -3160 0 -3160 0 0
-1090z m905 623 c174 -131 317 -283 388 -414 30 -54 67 -154 67 -180 0 -5 -28
-22 -63 -38 -136 -65 -212 -171 -226 -313 l-7 -73 -17 40 c-46 103 -117 349
-162 555 l-23 105 -1 -131 c-1 -194 36 -544 75 -701 5 -18 2 -21 -18 -16 -49
11 -206 94 -260 138 -186 150 -228 392 -115 670 51 125 238 425 265 425 4 0
48 -30 97 -67z m922 -770 c-12 -281 -92 -450 -246 -521 -46 -22 -71 -26 -141
-27 -88 0 -160 10 -160 24 0 27 136 223 215 310 l40 44 -45 -28 c-69 -43 -199
-147 -255 -204 -27 -28 -52 -51 -56 -51 -4 0 -15 31 -23 69 -74 327 133 516
568 520 l109 1 -6 -137z"/>
</g>
`]
