export const logoNegative = ['608 134', `
  <title>coreui react pro logo</title>
  <g transform="translate(0.000000,273.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 1365 l0 -1365 3950 0 3950 0 0 1365 0 1365 -3950 0 -3950 0 0
-1365z m2622 1140 c15 -11 36 -36 47 -56 21 -36 21 -44 21 -1084 0 -1040 0
-1048 -21 -1084 -11 -20 -32 -45 -47 -56 -27 -20 -45 -20 -1173 -23 l-1146 -2
-33 22 c-19 13 -43 39 -54 58 -21 35 -21 44 -21 1085 0 1041 0 1050 21 1085
11 19 35 45 54 58 l33 22 1146 -2 c1128 -3 1146 -3 1173 -23z m3224 -80 c108
-27 213 -73 301 -132 77 -51 173 -141 173 -163 0 -23 -287 -260 -315 -260 -12
0 -37 16 -56 36 -144 151 -362 176 -541 63 -136 -87 -218 -254 -218 -444 0
-182 98 -339 262 -419 170 -83 348 -63 488 54 26 22 56 40 66 40 11 -1 84 -47
164 -103 106 -75 146 -109 148 -126 7 -48 -205 -212 -356 -276 -208 -88 -428
-95 -644 -21 -221 75 -389 225 -493 440 -74 151 -89 222 -89 426 -1 153 2 185
22 253 71 246 217 432 433 551 135 74 258 104 435 105 99 1 140 -4 220 -24z
m-2328 -37 c9 -9 12 -104 12 -365 l0 -353 315 0 315 0 0 353 c0 261 3 356 12
365 9 9 70 12 213 12 143 0 204 -3 213 -12 17 -17 17 -1739 0 -1756 -15 -15
-390 -17 -419 -2 -18 10 -19 25 -19 325 l0 315 -315 0 -315 0 0 -313 c0 -230
-3 -316 -12 -325 -15 -15 -390 -17 -419 -2 -19 10 -19 31 -19 878 0 662 3 871
12 880 17 17 409 17 426 0z m4170 0 c17 -17 17 -369 0 -386 -9 -9 -69 -12
-210 -12 l-198 0 0 -675 c0 -656 -1 -675 -19 -685 -29 -15 -404 -13 -419 2 -9
9 -12 175 -12 685 l0 673 -198 0 c-141 0 -201 3 -210 12 -8 8 -12 66 -12 193
0 127 4 185 12 193 17 17 1249 17 1266 0z m-4471 -1980 c7 -6 13 -17 13 -24 0
-17 -32 -18 -48 -2 -9 9 -15 9 -23 1 -8 -8 -1 -14 25 -23 48 -15 63 -43 41
-76 -19 -29 -78 -33 -107 -7 -24 21 -23 43 2 43 l20 0 -20 20 c-23 23 -25 40
-7 62 23 29 73 32 104 6z m262 11 c37 -13 51 -35 51 -80 0 -73 -92 -108 -145
-54 -41 40 -26 113 29 135 28 12 28 12 65 -1z m1282 -15 c38 -35 28 -121 -16
-138 -82 -31 -158 38 -123 110 25 53 96 67 139 28z m523 15 c33 -16 42 -49 12
-49 -12 0 -27 5 -34 12 -9 9 -15 9 -23 1 -8 -8 2 -14 33 -24 36 -12 44 -19 46
-44 3 -19 -2 -33 -14 -42 -22 -16 -86 -17 -102 -1 -18 18 -15 48 5 48 15 0 15
1 -1 13 -26 19 -18 68 14 84 31 16 33 16 64 2z m705 0 c37 -13 51 -35 51 -80
0 -47 -33 -79 -82 -79 -99 0 -121 129 -28 163 22 8 24 8 59 -4z m-2039 -46 c0
-56 11 -78 34 -70 12 5 16 20 16 62 0 52 1 55 25 55 24 0 25 -3 25 -62 0 -75
-16 -98 -71 -98 -55 0 -69 19 -69 96 0 57 2 64 20 64 17 0 20 -7 20 -47z
m1037 15 l18 -33 3 33 c3 26 7 32 28 32 23 0 24 -2 24 -80 0 -78 -1 -80 -24
-80 -17 0 -31 11 -48 38 l-23 37 -3 -38 c-3 -33 -6 -38 -25 -35 -20 3 -22 9
-25 81 l-3 77 30 0 c24 0 34 -7 48 -32z m1397 16 c3 -9 6 -24 6 -35 0 -32 17
-21 24 16 6 28 11 35 31 35 20 0 25 -7 31 -35 7 -40 19 -38 27 5 5 21 12 30
27 30 24 0 25 -12 4 -95 -15 -60 -19 -65 -43 -65 -23 0 -29 6 -37 38 l-10 37
-10 -35 c-8 -27 -16 -36 -37 -38 -28 -3 -31 2 -55 116 -9 40 -9 42 13 42 13 0
26 -7 29 -16z m423 -58 c29 -86 29 -86 -2 -86 -16 0 -25 6 -25 15 0 10 -10 15
-30 15 -20 0 -30 -5 -30 -15 0 -9 -10 -15 -26 -15 -17 0 -24 5 -22 13 3 6 16
43 29 80 23 64 25 68 53 65 27 -3 31 -9 53 -72z m533 54 c0 -17 -7 -20 -40
-20 -22 0 -40 -4 -40 -10 0 -5 16 -10 35 -10 28 0 35 -4 35 -20 0 -16 -7 -20
-35 -20 -19 0 -35 -4 -35 -10 0 -5 18 -10 40 -10 33 0 40 -3 40 -20 0 -18 -7
-20 -65 -20 l-65 0 0 80 0 80 65 0 c58 0 65 -2 65 -20z m245 -4 c12 -18 13
-30 6 -50 -6 -14 -16 -26 -22 -26 -7 0 -3 -10 9 -23 29 -31 28 -37 -7 -37 -18
0 -34 8 -44 23 l-16 22 0 -22 c-1 -16 -7 -23 -21 -23 -18 0 -20 7 -20 80 l0
80 50 0 c41 0 52 -4 65 -24z"/>
<path d="M949 2142 c-232 -317 -339 -573 -339 -813 0 -204 75 -351 249 -487
76 -59 301 -166 301 -143 0 6 -6 40 -14 78 -8 37 -17 88 -21 113 -3 25 -10 70
-15 100 -10 61 -17 121 -25 225 -4 39 -8 86 -11 105 -9 82 -14 490 -6 490 5 0
17 -42 26 -92 29 -159 104 -453 161 -625 67 -201 74 -215 75 -148 0 61 22 151
51 208 36 70 134 159 224 202 44 22 81 40 83 41 2 1 -4 31 -14 66 -46 169
-184 363 -380 538 -76 68 -275 221 -285 220 -2 -1 -29 -35 -60 -78z"/>
<path d="M2050 1340 c-366 -31 -572 -170 -611 -412 -12 -70 -7 -138 18 -241
l16 -69 41 44 c89 94 222 203 341 279 43 28 113 65 105 56 -3 -3 -39 -43 -80
-87 -88 -96 -182 -224 -234 -320 l-37 -68 58 -12 c31 -7 98 -10 147 -8 116 5
201 42 280 121 118 118 186 350 186 637 l0 90 -72 -1 c-40 -1 -111 -5 -158 -9z"/>
</g>
`]
